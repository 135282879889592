exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-fabrication-js": () => import("./../../../src/pages/services/fabrication.js" /* webpackChunkName: "component---src-pages-services-fabrication-js" */),
  "component---src-pages-services-furniture-js": () => import("./../../../src/pages/services/furniture.js" /* webpackChunkName: "component---src-pages-services-furniture-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-welding-services-js": () => import("./../../../src/pages/services/welding-services.js" /* webpackChunkName: "component---src-pages-services-welding-services-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-gallery-detail-js": () => import("./../../../src/templates/gallery-detail.js" /* webpackChunkName: "component---src-templates-gallery-detail-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/project-detail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */),
  "component---src-templates-service-category-js": () => import("./../../../src/templates/service-category.js" /* webpackChunkName: "component---src-templates-service-category-js" */),
  "component---src-templates-service-detail-js": () => import("./../../../src/templates/service-detail.js" /* webpackChunkName: "component---src-templates-service-detail-js" */)
}

